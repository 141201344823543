<template>
  <div class="book_header">
    <div class="header">
      <!-- <div class="logo">
        <h1><a href="/" title="知行图书馆">知行图书馆</a></h1>
      </div> -->
      <div class="logo_text">
        <img src="@/assets/zhixinglib.png" alt="" />
      </div>

      <!-- <div class="cart"></div> -->
      <div class="login">
        <button v-if="loginUser == null" @click="loginClick">登录</button>
        <button @click="loginClick" v-else>用户:{{ loginUser.split("@")[0] }}</button>
      </div>
    </div>
    <nav class="navigation" @click="dainji">
      <ul>
        <li>
          <router-link active-class="liClick" to="/newEBooks">新品发布</router-link>
        </li>
        <li>
          <router-link active-class="liClick" to="/Search">全站搜书</router-link>
        </li>
        <!-- <li>
          <router-link active-class="liClick" to="/Video">学习视频</router-link>
        </li> -->
        <li>
          <router-link active-class="liClick" to="/partners">合作伙伴</router-link>
        </li>
        <!-- <li>
          <router-link active-class="liClick" to="/nihao">BOOKS</router-link>
        </li> -->
        <!-- <li>
          <router-link active-class="liClick" to="/nihao">CONTACE US</router-link>
        </li> -->
        <li>
          <router-link active-class="liClick" to="/reading">拓展阅读</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "ZhixingBookHeader",
  setup() {
    const router = useRouter();
    const store = useStore();
    const obj = reactive({
      loginUser: null,
    });

    const loginClick = () => {
      router.push("login");
    };

    onMounted(() => {
      obj.loginUser = localStorage.getItem("WebUser");
    });
    const dainji = ()=>{
      
      store.commit("SETTITLE","");
    }
    return {
      ...toRefs(obj),
      loginClick,
      dainji
    };
  },
};
</script>

<style lang="less" scoped>
.book_header {
  // height: 151.88px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 17px -5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  overflow: hidden;
  .navigation {
    width: 100%;
    // height: 4.38px;
    position: relative;
    ul {
      padding: 20px 0;
      margin: auto;
      text-align: center;
      box-sizing: border-box;
      li {
        font-size: 15px;
        font-weight: 900;
        letter-spacing: 2.16px;
        color: rgba(57, 50, 128, 1);
        display: inline-block;
        height: 17px;
        box-sizing: border-box;
        padding: 0 40px;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
      li:last-child {
        border-right: none !important;
      }
      .liClick {
        color: rgba(237, 85, 59, 1) !important;
      }
    }
  }
  .header {
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    // width: 100%;
    position: relative;
    height: 76.25px;
    box-sizing: border-box;
    padding: 0 226.38px 0 162px;
    border-bottom: 2px solid rgba(224, 224, 224, 1);
    .login {
      button {
        position: absolute;
        top: 25.75px;
        right: 190px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 28.96px;
        color: rgba(255, 255, 255, 1);
        width: auto;
        box-sizing: border-box;
        padding: 0 10px;
        height: 27.5px;
        opacity: 1;
        border-radius: 7px;
        background: rgba(250, 51, 70, 1);
        cursor: pointer;
      }
    }
    .cart {
      position: absolute;
      top: 25px;
      right: 184px;
      width: 32.43px;
      height: 29.55px;
      opacity: 1;
      background: url("@/assets/cart.png") no-repeat;
      background-size: 100% 100%;
    }
    .logo_text {
      display: inline-block;
      font-size: 16px !important;
      vertical-align: top;
      margin-left: 24px;
      box-sizing: border-box;
      padding-top: 11.38px;
      img {
        width: 220px;
        height: 50px;
      }
      h2 {
        margin: 0;
        height: 29px;
        margin-bottom: 5px;
      }
      h2:nth-child(2) {
        font-size: 16px !important;
      }
      span {
        color: rgba(250, 51, 70, 1);
      }
    }
    .logo {
      display: inline-block;
      h1 {
        display: inline-block;
        margin: 0;
        a {
          display: inline-block;
          width: 86px;
          height: 86px;
          background: url("@/assets/logo.png") no-repeat;
          background-size: 100% 100%;
          font-size: 0;
          vertical-align: top;
        }
      }
    }
  }
}
</style>
