<template>
  <div class="infomercial_box">
    <img class="bgimg" :src="imgurl" alt="" />
    <!-- 领取试听二维码 -->
    <Audition :num="num" />
    <!-- 优惠码列表 -->
    <DiscountDode :num="num" />
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import Audition from "./Audition.vue";
import DiscountDode from "./DiscountDode.vue";
import {useStore } from "vuex"
export default {
  name: "ZhixingBookInfomercial",
  components: { Audition, DiscountDode },
  props: ["num"],
  setup(props, context) {
    const sotre = useStore();
    const obj = reactive({
      imgurl: require("@/assets/g" + sotre.state.category_id + ".png"),
      num: sotre.state.category_id,
    });
    
    watch(
      () => sotre.state.category_id,
      () => {
        if(sotre.state.category_id==404 || sotre.state.category_id=="404"){
          return false;
        }
        obj.imgurl = require("@/assets/g" + sotre.state.category_id + ".png");
        obj.num = sotre.state.category_id;
      }
    );

    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.infomercial_box {
  width: 100%;
  height: 263.05px;
  // margin-bottom: 185px;
  // margin-top: 73px;
  position: relative;
  box-shadow: 0px 2px 17px -5px rgba(0, 0, 0, 0.25);
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
</style>
