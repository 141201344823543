import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible';
import './common/css/base.less';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/language/'

// import VueUeditorWrap from 'vue-ueditor-wrap';

createApp(App).use(store).use(router).use(ElementPlus).use(i18n).mount('#app')
