<template>
  <Header style="margin-bottom: 42px" />
  <!-- <Infomercial num="1" style="margin-bottom: 52px" /> -->
  <div class="Register_box">
    <div class="Register_left">
      <h1>欢迎来到知行图书馆！</h1>
      <p>
        在这里，语言与文化交织<br/>
        全年龄段的汉语资料种类齐全，等你领取！
      </p>
      <button @click="loginClick">立即登录</button>
    </div>
    <div class="Register_right">
      <h1>注册</h1>
      <p>解锁中文语言，畅享文化乐趣。</p>
      <div class="input_box">
        <div class="inbox">
          <h4>邮箱地址 *</h4>
          <div>
            <input type="text" v-model="webUser.emil" placeholder="请输入您的邮箱地址" />
          </div>
        </div>
        <div class="inbox">
          <h4>图形验证码 *</h4>
          <div class="code_box">
            <input
              type="text"
              v-model="webUser.imgCode"
              placeholder="请输入右侧图像验证码"
            />
            <img @click="getImgUrl" :src="imgurl" alt="" />
          </div>
        </div>
        <div class="inbox">
          <h4>邮箱验证码 *</h4>
          <div class="code_box">
            <input
              type="text"
              v-model="webUser.emilCode"
              placeholder="请输入邮箱验证码"
            />
            <button v-if="timeCode == 0" @click="SendingClick()">发送邮件</button>
            <button v-if="timeCode > 0">{{ timeCode }}</button>
          </div>
        </div>
        <div class="inbox">
          <h4>密码 *</h4>
          <div>
            <input type="password" v-model="webUser.password" placeholder="请输入密码" />
          </div>
        </div>
        <div class="inbox">
          <h4>确认密码 *</h4>
          <div>
            <input
              type="password"
              v-model="webUser.pwd_age"
              placeholder="请再次输入密码"
            />
          </div>
        </div>
      </div>
      <button @click="registerClick">提交注册</button>
    </div>
  </div>
  <Infomercial num="1" style="margin-bottom: 52px" />
  <Floor />
</template>

<script>
import Header from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";
import Infomercial from "@/components/Infomercial.vue";
import { useRouter } from "vue-router";
import { getCaptcha, sendCode, addEBookWebUser } from "@/api/getData";
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "ZhixingBookLogin",
  components: { Header, Floor, Infomercial },
  setup() {
    // 路由
    const router = useRouter();

    // 数据中心
    const obj = reactive({
      imgurl: null,
      timeCode: 0,
      webUser: {
        emil: null,
        imgCode: null,
        emilCode: null,
        password: null,
        pwd_age: null,
      },
    });

    // 获取图像验证码
    const getImgUrl = async () => {
      try {
        const res = await getCaptcha();
        const myBlob = new window.Blob([res.data], { type: "image/png" });
        obj.imgurl = window.URL.createObjectURL(myBlob);
      } catch {
        getImgUrl();
        ElMessage.error("网络异常，请刷新！");
      }
    };

    // 跳转登录
    const loginClick = () => {
      router.push("/login");
    };

    // 内容验证
    const checking = (number) => {
      let check = true;
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      if (!data.test(obj.webUser.emil)) {
        ElMessage.error("请输入正确的电子邮件地址！");
        check = false;
      }
      if (obj.webUser.imgCode == null) {
        ElMessage.error("内容不能为空！");
        check = false;
      }
      if (number == 1) {
        if (obj.webUser.password == null || obj.webUser.pwd_age == null) {
        ElMessage.error("内容不能为空！");
          check = false;
        }
        if (obj.webUser.password != obj.webUser.pwd_age) {
          ElMessage.error("输入的两个密码不匹配！");
          check = false;
        }
      }
      return check;
    };

    const codeJiajian = () => {
      let tiemint = setInterval(() => {
        obj.timeCode = obj.timeCode - 1;
        if (obj.timeCode <= 0) {
          obj.timeCode = 0;
          clearInterval(tiemint);
        }
      }, 1000);
    };

    // 发送邮箱验证码
    const SendingClick = async () => {
      if (!checking(0)) {
        return false;
      }
      try {
        const res = await sendCode(obj.webUser.emil, obj.webUser.imgCode);
        if (res.data.code != 0) {
          ElMessage.error("验证码不正确！");
          return false;
        }
        obj.timeCode = 60;
        codeJiajian();
      } catch {
        ElMessage.error("网络异常，请刷新！");
      }
    };

    // 提交注册
    const registerClick = async () => {
      if (!checking(1)) {
        return false;
      }
      try {
        let res = await addEBookWebUser(obj.webUser);
        if (res.data.code == 0) {
          ElMessage.success("注册成功");
          loginClick();
        } else {
          ElMessage.error(res.data.msg);
        }
      } catch (error) {
        ElMessage.error(error);
      }
    };

    // 初始化
    onMounted(() => {
      getImgUrl();
    });

    return {
      loginClick,
      ...toRefs(obj),
      getImgUrl,
      SendingClick,
      registerClick,
    };
  },
};
</script>

<style lang="less" scoped>
.Register_box {
  width: 900px;
  //   height: 646px;
  margin: auto;
  margin-bottom: 73px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .Register_right {
    box-shadow: 0px 1px 5px rgba(34, 25, 25, 0.2);
    width: 420px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    padding: 15px 20px;
    .input_box {
      width: 100%;
      //   min-height: 300px;
      margin-bottom: 20px;
      .inbox {
        width: 100%;
        h4 {
          margin: 10px 0;
          font-size: 12px;
        }
        .code_box {
          display: flex;
          input {
            margin-right: 10px;
            font-size: 12px;
          }
          button {
            height: 35px;
          }
          img {
            cursor: pointer;
          }
        }
        div {
          width: 100%;
          height: 35px;
          input {
            width: 100%;
            height: 35px;
            font-size: 12px;
            margin: 0;
            border: 1px solid #d6d6d6;
            outline: none;
            background-color: #f5f5f5;
            box-sizing: border-box;
            padding-left: 10px;
          }
        }
      }
    }
    h1 {
      color: #1a1668;
      font-size: 22px;
      font-weight: 600;
    }
    p {
      color: #847d77;
      font-size: 16px;
    }
    button {
      cursor: pointer;
      background-color: rgba(57, 50, 128, 1);
      color: #fff;
      width: 120px;
      height: 38px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  .Register_left {
    box-shadow: 0px 1px 5px rgba(34, 25, 25, 0.2);
    width: 370px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 20px;
    min-height: 300px;
    border-radius: 6px;
    h1 {
      color: #1a1668;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    p {
      color: #847d77;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 1px;
    }
    button {
      cursor: pointer;
      background-color: rgba(57, 50, 128, 1);
      color: #fff;
      width: 120px;
      height: 38px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
}
</style>
