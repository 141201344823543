import { createStore } from 'vuex'

export default createStore({
  state: {
    title:null,
    category_id:0,
    reading_category_id:0
  },
  mutations: {
    // 修改搜索输入框类容
    SETTITLE(state,title){
      state.title = title;
    },
    // 修改书籍分类id
    SCATEHORYID(state,category_id){
      state.category_id = category_id;
    },
    // 修改书籍分类id
    READINGCATEGORYID(state,reading_category_id){
      state.reading_category_id = reading_category_id;
    },
  },
  actions: {
  },
  modules: {
  }
})
