<template>
  <div class="Floor">
    <div class="Floor_box">
      <div class="Floor_left">
        <!-- <img class="logo" src="@/assets/logo.png" alt="" /> -->
        <img class="logolib" src="@/assets/logolib.png" alt="" />
        <p>在这里，语言与文化交织 </p> 
        <p>全年龄段的汉语资料种类齐全，等你领取！</p>
        
        <img class="icon" src="@/assets/icon.png" alt="" />
      </div>
      <div class="Floor_right">
        <ul>
          <li>导航</li>
          <li @click="liClick(1)">首页</li>
          <li @click="liClick(2)">新品发布</li>
          <li @click="liClick(3)">合作伙伴</li>
          <li @click="liClick(4)">拓展阅读</li>
        </ul>
        <ul>
          <li>友情链接</li>
          <li><a href="https://www.chinese-learning.cn/#/web" target="_blank">全球中文学习平台</a></li>
          <li><a href="https://www.zdic.net/" target="_blank">在线汉语词典</a></li>
          <li><a href="http://www.yes-chinese.com/" target="_blank">中文天下</a></li>
        </ul>
      </div>
    </div>
    <div class="Floor_context">
        © 2023 Arihant. All Rights Reserved.
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router"
export default {
  name: "ZhixingBookFloor",
  setup() {
    const router = useRouter();
    const liClick = (type)=>{
      switch (type) {
        case 1:
          router.push("/Search");
          break;
        case 2:
          router.push("/newEBooks");
          break;
        case 3:
          router.push("/partners");
          break;
        case 4:
          router.push("/reading");
          break;
      
        default:
          router.push("/");
          break;
      }
    }
    return {
      liClick
    }
  },
};
</script>

<style lang="less" scoped>
.Floor {
  width: 100%;
  height: 325px;
  background-color: rgba(57, 50, 128, 1);
  box-sizing: border-box;
  padding-top: 38.31px;
  margin-top: 40px;
  .Floor_context{
    margin: auto;
    width: 628px;
    height: 14px;
    color:#FFF;
    font-size: 11.25px;
    font-weight: 400;
    letter-spacing: 0.36px;
    text-align: center;
  }
  .Floor_box {
    margin: auto;
    width: 700px; 
    height: 240px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;

    .Floor_right {
      width: 350px;
      height: 100%;
      display: flex;
      box-sizing: border-box;
      // padding-top: 25px;
      ul {
        margin: 0;
        padding: 0;
        color: #fff;
        li {
          font-size: 11.25px;
          font-weight: 600;
          letter-spacing: 0.36px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 10px;
          cursor: pointer;
          a{
          font-size: 11.25px;
          font-weight: 600;
          letter-spacing: 0.36px;
          color: rgba(255, 255, 255, 1);
          }
        }
        li:nth-child(1) {
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0.48px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 18px;
        }
      }
      ul:nth-child(1){
        margin: 0 100px 0 0;
      }
    }
    .Floor_left {
      height: 100%;
      width: 400px;
      padding-left: 35px;
      .icon {
        width: 228.75px;
        height: 27.61px;
        margin-top: 31.63px;
      }
      p {
        font-size: 11.25px;
        font-weight: 400;
        letter-spacing: 1.8px;
        color: rgba(255, 255, 255, 1);
        margin: 0 0 10px 0;
      }
      .logo {
        width: 71.25px;
        height: 71.25px;
        margin-bottom: 13.75px;
      }
      .logolib {
        width: 271.25px;
        height: 60.25px;
        margin-bottom: 13.75px;
      }
    }
  }
}
</style>
