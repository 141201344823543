<template>
    <Header /> 
    <router-view></router-view>
    <Floor />
</template>

<script>
import Header from '@/components/Header.vue';
import Floor from '@/components/Floor.vue';
import { reactive } from 'vue';
export default {
    name: 'ZhixingBookLayout',
    components:{Header,Floor},
    setup(){

    }
};
</script>

<style lang="less" scoped>

</style>