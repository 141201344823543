<template>
  <Header style="margin-bottom: 42px" />
  <!-- <Infomercial num="1" style="margin-bottom: 52px" /> -->
  <div class="Register_box">
    <div class="Register_left">
      <h1>欢迎来到知行图书馆！</h1>
      <p>
        在这里，语言与文化交织<br />
        全年龄段的汉语资料种类齐全，等你领取！
      </p>
      <button @click="loginClick">立即注册</button>
    </div>
    <div class="Register_right">
      <h1>登录</h1>
      <p>与中文的智慧相遇，开拓未来无限可能！</p>
      <div class="input_box">
        <div class="inbox">
          <h4>邮箱地址 *</h4>
          <div>
            <input type="text" v-model="webUser.emil" placeholder="请输入您的邮箱地址" />
          </div>
        </div>
        <div class="inbox">
          <h4>密码 *</h4>
          <div>
            <input type="password" v-model="webUser.password" placeholder="请输入密码" />
          </div>
        </div>
      </div>
      <button @click="registerClick">登录</button>
    </div>
  </div>
  <Infomercial num="1" style="margin-bottom: 52px" />
  <Floor />
</template>

<script>
import Header from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";
import Infomercial from "@/components/Infomercial.vue";
import { useRouter } from "vue-router";
import { loginWebUser } from "@/api/getData";
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
export default {
  name: "ZhixingBookLogin",
  components: { Header, Floor, Infomercial },
  setup() {
    // 路由
    const router = useRouter();

    // 数据中心
    const obj = reactive({
      imgurl: null,
      timeCode: 0,
      webUser: {
        emil: null,
        password: null,
      },
    });

    // 跳转登录
    const loginClick = () => {
      router.push("/register");
    };

    // 内容验证
    const checking = (number) => {
      let check = true;
      const data = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      if (!data.test(obj.webUser.emil)) {
        ElMessage.error("请输入正确的电子邮件地址！");
        check = false;
      }
      if (obj.webUser.password == null) {
        ElMessage.error("内容不能为空！");
        check = false;
      }
      return check;
    };

    // 提交注册
    const registerClick = async () => {
      if (!checking(1)) {
        return false;
      }
      try {
        console.log(obj.webUser);
        let res = await loginWebUser(obj.webUser);
        if (res.data.code == 0) {
          localStorage.setItem("WebUser", obj.webUser.emil);
          localStorage.setItem("BookUser", JSON.stringify(res.data.data));
          ElMessage.success("登录成功！");
          router.push("/");
        } else {
          ElMessage.error(res.data.msg);
        }
      } catch (error) {
        ElMessage.error(error);
      }
    };

    // 初始化
    onMounted(() => {});

    return {
      loginClick,
      ...toRefs(obj),
      registerClick,
    };
  },
};
</script>

<style lang="less" scoped>
.Register_box {
  width: 900px;
  //   height: 646px;
  margin: auto;
  margin-bottom: 73px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .Register_right {
    box-shadow: 0px 1px 5px rgba(34, 25, 25, 0.2);
    width: 420px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    padding: 15px 20px;
    .input_box {
      width: 100%;
      //   min-height: 300px;
      margin-bottom: 20px;
      .inbox {
        width: 100%;
        h4 {
          margin: 10px 0;
          font-size: 12px;
        }
        .code_box {
          display: flex;
          input {
            margin-right: 10px;
            font-size: 12px;
          }
          button {
            height: 35px;
          }
          img {
            cursor: pointer;
          }
        }
        div {
          width: 100%;
          height: 35px;
          input {
            width: 100%;
            height: 35px;
            font-size: 12px;
            margin: 0;
            border: 1px solid #d6d6d6;
            outline: none;
            background-color: #f5f5f5;
            box-sizing: border-box;
            padding-left: 10px;
          }
        }
      }
    }
    h1 {
      color: #1a1668;
      font-size: 22px;
      font-weight: 600;
    }
    p {
      color: #847d77;
      font-size: 16px;
    }
    button {
      cursor: pointer;
      background-color: rgba(57, 50, 128, 1);
      color: #fff;
      width: 120px;
      height: 38px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  .Register_left {
    box-shadow: 0px 1px 5px rgba(34, 25, 25, 0.2);
    width: 370px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 15px 20px;
    min-height: 300px;
    border-radius: 6px;
    h1 {
      color: #1a1668;
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    p {
      color: #847d77;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 1px;
    }
    button {
      cursor: pointer;
      background-color: rgba(57, 50, 128, 1);
      color: #fff;
      width: 120px;
      height: 38px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
}
</style>
