import { createRouter, createWebHashHistory } from 'vue-router';
import Layout from '../views/Layout.vue';
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";


const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children:[
      {
        path:"/",
        redirect: "/newEBooks"
      },
      {
        path:"/Search",
        name:"Search",
        component: ()=> import("@/views/searchbooks/index.vue")
      },
      {
        path:"/Video",
        name:"Video",
        component: ()=> import("@/views/searchvideos/index.vue")
      },
      {
        path:"/Detail",
        name:"Detail",
        component: ()=> import("@/views/bookdetail/index.vue")
      },
      {
        path:"/newEBooks",
        name:"NewEBooks",
        component: ()=> import("@/views/newebooks/index.vue")
      },
      {
        path:"/reading",
        name:"Reading",
        component: ()=> import("@/views/furtherreading/index.vue")
      },
      {
        path:"/partners",
        name:"Partners",
        component: ()=> import("@/views/partners/index.vue")
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
