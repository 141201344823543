<template>
  <div :class="discountcode">
    <button class="button" @click="discountClick">
      <img src="@/assets/dianji.png" alt="" /> <span>点击生成课程优惠码</span>
    </button>
    <div class="code_list">
      <button v-if="shownum >= 0" class="list" title="点击复制" @click="copy(dic_doce?.split(',')[0])">
        <span>{{dic_doce?.split(",")[0]}}</span>
        <i @click.stop="shownumClick" class="iconfont icon-jia"></i>
      </button>
      <button v-if="shownum >= 1" class="list" title="点击复制" @click="copy(dic_doce?.split(',')[1])">
        <span>{{dic_doce?.split(",")[1]}}</span>
        <i @click.stop="shownumClick" class="iconfont icon-jia"></i>
      </button>
      <button v-if="shownum >= 2" class="list"  title="点击复制" @click="copy(dic_doce?.split(',')[2])">
        <span>{{dic_doce?.split(",")[2]}}</span>
      </button>
    </div>
    <div class="txt"> <span >新学员</span>使用优惠码免费兑换2节正式课程</div>
  </div>
</template>

<script>
import { reactive, toRefs,watch,onMounted } from "vue";
import {useStore } from "vuex";
import {getDiscountCode} from "@/api/getData";
import {addCouponOrderNo} from "@/api/getCouponData";
import { ElMessage } from "element-plus";
import useClipboard from "vue-clipboard3";
import {useRouter} from "vue-router"
export default {
  name: "ZhixingBookDiscountDode",
  props: ["num"],
  setup(props, context) {
    const { toClipboard } = useClipboard();
    const sotre = useStore();
    const router = useRouter();
    const obj = reactive({
      num: (sotre.state.category_id*1+1),
      discountcode: "DiscountCode_box codeClass" + (sotre.state.category_id*1+1) ,
      shownum: 0,
      dic_doce:null
    });
    const shownumClick = () => {
      obj.shownum = obj.shownum + 1;
    };
    watch(
      () =>  (sotre.state.category_id*1+1),
      () => {
        obj.discountcode = "DiscountCode_box codeClass" +  (sotre.state.category_id*1+1);
      }
    );

    const copy = async (text) => {
      
      if(text==null || text=='' || text == undefined){
        return false;
      }
      try {
        await toClipboard(text);  //实现复制
        ElMessage.success("复制成功！")
      } catch (e) {
        ElMessage.error(e);
      }
    };
    // 生成优惠码
    const discountClick = async ()=>{
      let bookuser = localStorage.getItem("BookUser");
      if(bookuser==null){
        ElMessage.error("请先登录！");
        router.push("/login")
        return false;
      }
      let user = JSON.parse(bookuser);
      if(user.discount*1>=3){
        ElMessage.error("每个用户最多获取三个优惠码!");
        return false;
      }
      try {
        let couponData = {};
        couponData.coupon_type = 1;
        couponData.coupon_zh_text = "电子书网站获取兑课优惠码";
        couponData.sessions = 2;
        let coupon = await addCouponOrderNo(couponData);
        user.dic_doce = coupon.data.data;
        let res = await getDiscountCode(user);
        if(res.data.code!=0){
          ElMessage.error(res.data.msg);
          return false;
        }
        ElMessage.success("获取优惠券成功！")
        let backUser = res.data.data;
        obj.shownum = backUser.discount*1-1;
        obj.dic_doce = backUser.dic_doce;
        localStorage.setItem("BookUser",JSON.stringify(backUser));
      } catch (error) {
        ElMessage.error(error)
      }
    }

    // 初始化
    onMounted(()=>{
      let bookuser = localStorage.getItem("BookUser");
      if(bookuser!=null){
        let user = JSON.parse(bookuser);
        obj.dic_doce = user.dic_doce;
        obj.shownum = user.discount*1-1<=0?0:user.discount*1-1;
      }
    })
    return {
      ...toRefs(obj),
      shownumClick,
      discountClick,
      copy
    };
  },
};
</script>

<style lang="less" scoped>
.DiscountCode_box {
  position: absolute;
  top: 0;
  right: 57.76px;
  width: 306.88px;
  height: 250px;
  z-index: 50;
  box-sizing: border-box;
  padding-top: 9.38px;
  .code_list {
    width: 100%;
    height: 165px;
    box-sizing: border-box;
    padding-top: 16px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .list {
      cursor: pointer;
      width: 306.88px;
      height: 41.88px;
      border-radius: 12.5px;
      position: relative;
      margin-bottom: 10px;
      font-size: 27.5px;
      font-weight: 700;
      color: rgba(0, 0, 0, 1);
      i {
        cursor: pointer;
        position: absolute;
        font-size: 18px;
        left: 50%;
        margin-left: -8px;
        bottom: -30px;
      }
    }
  }
  .txt {
    width: 100%;
    font-size: 12.5px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    span{
      color:red;
      font-weight: 600;
    }
  }
  .button {
    display: block;
    margin: auto;
    width: 293.75px;
    height: 63.75px;
    border-radius: 31.88px;
    font-size: 22.5px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    vertical-align: middle;
    cursor: pointer;
    span {
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
      width: 24.8px;
      height: 29.63px;
    }
  }
}

@topClass1: rgba(196,185,157, 1);
@botClass1: rgba(179, 119, 73, 1);
.codeClass1 {
  .code_list {
    .list {
      background-color: @topClass1;
      i {
        color: @topClass1;
      }
    }
  }
  .button {
    background-color: @botClass1;
  }
}

@topClass2: rgba(255, 219, 219, 1);
@botClass2: rgba(227, 95, 122, 1);
.codeClass2 {
  .code_list {
    .list {
      background-color: @topClass2;
      i {
        color: @topClass2;
      }
    }
  }
  .button {
    background-color: @botClass2;
  }
}

@topClass3: #fff;
@botClass3: rgba(33, 144, 76, 1);
.codeClass3 {
  .code_list {
    .list {
      background-color: @topClass3;
      i {
        color: @topClass3;
      }
    }
  }
  .button {
    background-color: @botClass3;
  }
}

@topClass4: #fff;
@botClass4:  rgba(50, 134, 135, 1);
.codeClass4 {
  .code_list {
    .list {
      background-color: @topClass4;
      i {
        color: @topClass4;
      }
    }
  }
  .button {
    background-color: @botClass4;
  }
}

@topClass5: #fff;
@botClass5: rgba(179, 29, 159, 1);
.codeClass5 {
  .code_list {
    .list {
      background-color: @topClass5;
      i {
        color: @topClass5;
      }
    }
  }
  .button {
    background-color: @botClass5;
  }
}

@topClass6: #fff;
@botClass6:rgba(199, 133, 62, 1);
.codeClass6 {
  .code_list {
    .list {
      background-color: @topClass6;
      i {
        color: @topClass6;
      }
    }
  }
  .button {
    background-color: @botClass6;
  }
}

@topClass7: rgba(227, 227, 227, 1);
@botClass7: rgba(58, 85, 181, 1);
.codeClass7 {
  .code_list {
    .list {
      background-color: @topClass7;
      i {
        color: @topClass7;
      }
    }
  }
  .button {
    background-color: @botClass7;
  }
}


@topClass8: rgba(196,185,157, 1);
@botClass8: rgba(179, 119, 73, 1);
.codeClass8 {
  .code_list {
    .list {
      background-color: @topClass8;
      i {
        color: @topClass8;
      }
    }
  }
  .button {
    background-color: @botClass8;
  }
}

.codeClass9 {
  .code_list {
    .list {
      background-color: @topClass8;
      i {
        color: @topClass8;
      }
    }
  }
  .button {
    background-color: @botClass8;
  }
}
</style>
