import axios from "axios";

const service = axios.create({
    baseURL: "https://victor.wxc1997cheshi.top",
    // baseURL: "https://zhixingweb.wxc1997cheshi.top",
    // baseURL:"https://stripe.wxc1997cheshi.top",
    // baseURL:"http://127.0.0.1:19006",
    timeout: 10000
})
 
export default service;
