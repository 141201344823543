<template>
  <div :class="MyAudition">
    <div class="title">
        <span>现在报名</span>，<span>0元</span>
        <span>领取试听课程</span>
    </div>
    <div class="text">
        <div><span></span></div>
        <div>赠送试听，无任何费用</div>
    </div>
    <div class="text">
        <div><span></span></div>
        <div>便捷注册，了解课程详情</div>
    </div>
    <div class="qrcode">
        <div>
            <img src="@/assets/wechat.png" alt="">
            <span>(WeChat)</span>
        </div>
        <div>
            <img src="@/assets/whatapp.png" alt="">
            <span>(WhatsAPP)</span>
        </div>
    </div>
  </div>
  <div :class="MyYuan">
    <button>扫码领取</button>
  </div>
</template>

<script>
import { reactive, toRefs, watch  } from "vue";
import {useStore } from "vuex"
export default {
  name: "ZhixingBookAudition",
  props: ["num"],
  setup(props, context) {
    const sotre = useStore();
    const obj = reactive({
      MyAudition: "Audition_box numClass" +  (sotre.state.category_id*1+1),
      MyYuan: "MyYuan yuanClass" +  (sotre.state.category_id*1+1),
    });

    watch(
      () =>  (sotre.state.category_id*1+1),
      () => {
        obj.MyAudition = "Audition_box numClass" +  (sotre.state.category_id*1+1);
        obj.MyYuan = "MyYuan yuanClass" +  (sotre.state.category_id*1+1);
      }
    );
    return {
      ...toRefs(obj),
    };
  },
};
</script>

<style lang="less" scoped>
.Audition_box {
  position: absolute;
  top: 0;
  right: 445.63px;
  z-index: 30;
  width: 187.5px;
  height: 173.75px;
  .qrcode{
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    margin:20px auto 6.88px auto;
    font-size: 7.5px;
    color:#FFF;
    text-align: center;
    div{
        width: 80px;
        height: 100%;
        img{ 
            width: 50px;
            height: 50px;
            margin-bottom: 2px; 
        }
    }
  }
  .text{
    width: 130px;
    height: 14px;
    margin: auto auto 9.13px 23px;
    display: flex;
    font-size: 10px;
    position: relative;
    box-sizing: border-box;
    padding-left: 10px;
    div:nth-child(1){
        position: absolute;
        left:-4px;
        top:3px;
        width: 8.75px;
        height: 8.75px;
        border-radius: 50%;
        box-sizing: border-box;
        span{
            position: absolute;
            top:50%;
            left:50%;
            margin: -2px 0 0 -2px;
            width: 4px;
            height: 4px;
            box-sizing: border-box;
            border-radius: 50%;
        }
    }
  }
  .title{
    width: 211.2px;
    height: 34.38px;
    border-radius: 8.25px;
    font-size: 14.85px;
    box-sizing: border-box;
    margin-left: -24px;
    padding-top: 6.88px;
    margin-top: 10.63px;
    text-align: center;
    color:#FFF;
    margin-bottom: 20px;
    span:nth-child(1),span:nth-child(2){
        color:rgba(255, 220, 153, 1);
    }
  }
}
.MyYuan {
  position: absolute;
  top: 75px;
  right: 445.63px;
  width: 187.5px;
  height: 187.5px;  
  z-index: 21;
  border-radius: 50%;
  button{
    position: absolute;
    left: 50%;
    bottom:18px;
    margin-left: -35px;
    width: 75px;
    height: 18.75px;
    border-radius: 8.2px;
    color:#FFF;
  }
}
@backgrColor1:rgba(225, 219, 206, 1);
@borderColor1:rgba(214, 206, 186, 1);
@bgaborColor1:rgba(179, 119, 73, 1);
  
.numClass1 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor1;
  border-left: 12.25px solid @backgrColor1;
  border-right: 12.25px  solid @backgrColor1;
  background: @borderColor1;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor1;
        span{
            display: block;
            background-color: @bgaborColor1;
        }
    }
  }
  .title{
    background-color:  @bgaborColor1;
  }
}
.yuanClass1 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor1;
  background: @borderColor1;
  button{
    background: @bgaborColor1;
  }
}
   

@backgrColor2:rgba(247, 144, 165, 1);
@borderColor2:rgba(253, 192, 197, 1);
@bgaborColor2:rgba(227, 95, 122, 1);
.numClass2 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor2;
  border-left: 12.25px solid @backgrColor2;
  border-right: 12.25px  solid @backgrColor2;
  background: @borderColor2;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor2;
        span{
            display: block;
            background-color: @bgaborColor2;
        }
    }
  }
  .title{
    background-color:  @bgaborColor2;
  }
  
}
.yuanClass2 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor2;
  background: @borderColor2;
  button{
    background: @bgaborColor2;
  }
}


@backgrColor3:rgba(90, 165, 97, 1);
@borderColor3:rgba(199, 220, 167, 1);
@bgaborColor3:rgba(90, 165, 97, 1);
.numClass3 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor3;
  border-left: 12.25px solid @backgrColor3;
  border-right: 12.25px  solid @backgrColor3;
  background: @borderColor3;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor3;
        span{
            display: block;
            background-color: @bgaborColor3;
        }
    }
  }
  .title{
    background-color:  @bgaborColor3;
  }
}
.yuanClass3 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor3;
  background: @borderColor3;
  button{
    background: @bgaborColor3;
  }
}

@backgrColor4:rgba(88, 163, 164, 1);
@borderColor4:rgba(213, 241, 245, 1);
.numClass4 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor4;
  border-left: 12.25px solid @backgrColor4;
  border-right: 12.25px  solid @backgrColor4;
  background: @borderColor4;
  .text{
    div:nth-child(1){
        border:1.5px solid @backgrColor4;
        span{
            display: block;
            background-color: @backgrColor4;
        }
    }
  }
  .title{
    background-color:  @backgrColor4;
  }
}
.yuanClass4 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor4;
  background: @borderColor4;
  button{
    background: @backgrColor4;
  }
}

@backgrColor5:rgba(201, 52, 182, 1);
@borderColor5:rgba(227, 159, 218, 1);
@bgaborColor5:rgba(201, 52, 182, 1);
.numClass5 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor5;
  border-left: 12.25px solid @backgrColor5;
  border-right: 12.25px  solid @backgrColor5;
  background: @borderColor5;
  .text{
    div:nth-child(1){
        border:1.5px solid @backgrColor5;
        span{
            display: block;
            background-color: @backgrColor5;
        }
    }
  }
  .title{
    background-color:  @bgaborColor5;
  }
}
.yuanClass5 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor5;
  background: @borderColor5;
  button{
    background: @bgaborColor5;
  }
}

@backgrColor6:rgba(222, 171, 115, 1);
@borderColor6:rgba(247, 236, 221, 1);
@bgaborColor6:rgba(222, 171, 115, 1);
.numClass6 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor6;
  border-left: 12.25px solid @backgrColor6;
  border-right: 12.25px  solid @backgrColor6;
  background: @borderColor6;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor6;
        span{
            display: block;
            background-color: @bgaborColor6;
        }
    }
  }
  .title{
    background-color:  @bgaborColor6;
  }
}
.yuanClass6 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor6;
  background: @borderColor6;
  button{
    background: @bgaborColor6;
  }
}

@backgrColor7:rgba(76, 106, 212, 1);
@borderColor7:rgba(212, 221, 255, 1);
@bgaborColor7:rgba(58, 85, 181, 1);
.numClass7 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor7;
  border-left: 12.25px solid @backgrColor7;
  border-right: 12.25px  solid @backgrColor7;
  background: @borderColor7;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor7;
        span{
            display: block;
            background-color: @bgaborColor7;
        }
    }
  }
  .title{
    background-color:  @bgaborColor7;
  }
}
.yuanClass7 {
  box-sizing: border-box;
  border: 12.5px solid @backgrColor7;
  background: @borderColor7;
  button{
    background: @bgaborColor7;
  }
}

@backgrColor8:rgba(225, 219, 206, 1);
@borderColor8:rgba(214, 206, 186, 1);
@bgaborColor8:rgba(179, 119, 73, 1);
  
.numClass8,.numClass9 {
  box-sizing: border-box;
  border-top: 12.25px solid @backgrColor8;
  border-left: 12.25px solid @backgrColor8;
  border-right: 12.25px  solid @backgrColor8;
  background: @borderColor8;
  .text{
    div:nth-child(1){
        border:1.5px solid @bgaborColor8;
        span{
            display: block;
            background-color: @bgaborColor8;
        }
    }
  }
  .title{
    background-color:  @bgaborColor8;
  }
}
.yuanClass8 , .yuanClass9{
  box-sizing: border-box;
  border: 12.5px solid @backgrColor8;
  background: @borderColor8;
  button{
    background: @bgaborColor8;
  }
}
</style>
