import axios from "../request";

const nacosUrl = "";
// const nacosUrl = "/website-server";

//用户提交预约 其他服务
// export const addReservation = (data) => axios({
//     method:"post",
//     url:"/api/v1/user_reservation/add_reservation",
//     data:data
// })

//获取图像验证码
export const getCaptcha = () => axios.get("/api/notify/v1/captcha", {  responseType: 'blob' });

//发送邮箱验证码
export const sendCode = (to,captcha) => axios.get("/api/notify/v1/send_code?to="+to+"&captcha="+captcha);

// 注册新用户
export const addEBookWebUser = (data) => axios({
    method:"post",
    url:"/api/web_user/v1/add_e_book_web_user",
    data:data
})

// 登录
export const loginWebUser = (data) => axios({
    method:"post",
    url:"/api/web_user/v1/login_web_user",
    data:data
})
// 获取优惠码
export const getDiscountCode = (data) => axios({
    method:"post",
    url:"/api/web_user/v1/get_discount_code",
    data:data
})

// 添加用户下载书籍记录
export const addEBookUserDown = (data) => axios({
    method:"post",
    url:"/api/v1/e_book_user_down/add_e_book_user_down",
    data:data
})

// 查询扩展阅读列表
export const getFurtherReadings = (category_id) => axios.get("/api/v1/further_reading/get_further_reading/"+category_id);
// 扩展阅读分类
export const getReadingCategory = () => axios.get("/api/v1/reading_category/get_reading_category");

// 搜索书籍
export const searchEBookList = (category_id,top_book_id,title) => axios.get("/api/e_book/v1/search_e_book_list/"+category_id+"/"+title+"/"+top_book_id);
// 书籍分类列表
export const getEBookCategoryList = () => axios.get("/api/e_book_category/v1/get_e_book_category_list");
// 新品发布
export const newEBooks = () => axios.get("/api/e_book/v1/new_e_books");
// 书籍详细
export const getEBookDetail = (id) => axios.get("/api/e_book/v1/get_e_book_detail/"+id);